<template>
    <!-- <div>{{ index }} - {{ source.text }}</div> -->
    <div class="box-card" :key="index" :id="`card-${source.contentId}`"
        :class="{ 'box-card-active': source.contentId === selectId ,'box-card-add': source.contentUpdateStatus === 1,  'box-card-edit': source.contentUpdateStatus === 2, 'box-card-del': source.contentUpdateStatus === 3}" @click="handleCardClick(source)">
        <div style="margin-bottom: 15px;">
            <div class="book-action" ref="title">
                {{ source.bookContentTitle }}
                <el-tag v-if="source.contentUpdateStatus > 0"  effect="dark" size="medium" :type="source.contentUpdateStatus === 1 ? 'success' : source.contentUpdateStatus === 2 ? 'warning' : source.contentUpdateStatus === 3 ? 'danger' : 'info'"
                >{{source.contentUpdateStatus === 1 ? '新建' : source.contentUpdateStatus === 2 ? '更新' : source.contentUpdateStatus === 3 ? '删除' : '' }}</el-tag>
                <el-switch v-if="menuType === 'knowledge'"  v-model="source.enableStatus" @change="switchChange($event, source)">
                </el-switch>
            </div>
            <div class="book-content">
                {{ source.bookContent }}
            </div>
        </div>
        <div class="book-footer">
            <div class="book-keyword-wrap">
                <div class="book-keyword">
                    关键字:
                    <span v-if="source.bookContentKeyword">
                        <el-tag size="small" :effect="source.contentUpdateStatus > 0 ? 'plain' : 'light'" :type=" source.contentUpdateStatus === 1 ? 'success' : source.contentUpdateStatus === 2 ? 'warning' : source.contentUpdateStatus === 3 ? 'danger' : 'info'" style="margin-right: 5px;margin-top: 5px;"
                            v-for="(item, index) in source.bookContentKeyword.split('|')" :key="index">{{ item
                            }}</el-tag>
                    </span>
                    <span v-else>暂无</span>
                </div>
                <i v-if="menuType === 'book' && source.contentUpdateStatus !== 3" class="el-icon-delete" style="font-size: 18px; cursor: pointer;" @click.stop="handleDelete(source)"></i>
            </div>
            <div class="book-solt-wrap">
                槽位:
                <span v-if="source.slotInfos">
                        <el-tag size="medium" effect="dark" style="margin-right: 5px;margin-top: 5px;" :color="item.slotColor" :style="{'border-color': item.slotColor}"
                            v-for="(item, index) in source.slotInfos" :key="index">{{ item.slotName }}</el-tag>
                    </span>
                <span v-else>暂无</span>
            </div>
        </div>
    </div>
</template>

<script>
import {changeBookContentStatus} from "../api/api";
export default {
    name: 'item-component',
    props: {
        index: { // index of current item
            type: Number
        },
        source: { // here is: {uid: 'unique_1', text: 'abc'}
            type: Object,
            default() {
                return {}
            }
        },
        handleCardClick: {
            type: Function,
            default: () => { }
        },
        handleDelete: {
            type: Function,
            default: () => { }
        },
        selectId: {
            type: Number
        },
        menuType: {
            type: String
        },
        knowledgeId: {
            type: Number
        }
    },
    methods: {
        switchChange(val, row) {
            changeBookContentStatus({
                bookId: row.bookId,
                contentId : row.contentId,
                knowledgeId: this.knowledgeId,
                status : row.enableStatus
            }).then((res) => {
                if (res.data.success) {
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: '操作失败, 请重试',
                        type: 'error'
                    });
                    row.enableStatus = !val
                }
            }).catch((err) => {
                this.$message({
                        message: '操作失败, 请重试',
                        type: 'error'
                    });
                    row.enableStatus = !val
            })
        }
    }
}
</script>
<style scoped>
.book-footer {
    width: 100%;
}
.book-keyword-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}

.book-keyword {
    width: 93%;
}

.tree-box {
    width: 37%;
    margin-top: 20px;
    overflow-y: scroll;
    max-height: 500px;
}

.right-content {
    width: 65%;
    overflow-y: scroll;
    height: 500px;
    padding: 20px;
}

.box-card {
    width: 93%;
    min-height: 200px;
    /* box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1); */
    margin-bottom: 20px;
    padding: 14px 17px;
    background: #F2F3F5;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.box-card-active {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
    background: #FFF;
    border-radius: 4px;
    border: 1px solid #409EFF;
}

.book-action {
    font-size: 15px;
    font-weight: bolder;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.box-card-add{
    border: 1px solid #07B700;
    background: #EEFFEE;
}
.box-card-edit{
    border: 1px solid #FFB143;
    background: #FFF9F1;
}
.box-card-del{
    border: 1px solid #FF5050;
    background: #FFF8F8;

}
</style>